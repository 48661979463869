import CommonFirstScreen from "~/components/shared/CommonFirstScreen";
import { createMemo, For, Show } from "solid-js";
import CommonFirstScreenImage from "~/components/shared/CommonFirstScreenImage";
import CommonIntroduction from "~/components/shared/CommonIntroduction";
import { urlRs } from "~/utils/url";
import { formatDateUStoFrenchShortString } from "~/utils/format";
import SectionParagraphComponent from "~/components/shared/SectionParagraphComponent";
import ContactPart from "~/components/PressReleases/Components/ContactPart";
import RelatedContentComponent from "~/components/shared/RelatedContentComponent";
import {
  AutopromosRenderer,
  hasMultipleShortcodes,
} from "~/components/shared/Autopromos/AutopromosRenderer";
import Breadcrumb from "~/components/shared/Breadcrumb";

import type { PressRelease, SectionParagraph } from "~/types/drupal_jsonapi";
import type { BreadcrumbItem } from "~/types/breadcrumb";

import "~/components/shared/CommonPageStyle.css";
import "./PressRelease.css";
import GtmVars from "../shared/Trackers/GtmVars";

export default function PressRelease(props: { pressRelease: PressRelease }) {
  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      href: urlRs("press_releases", "/espace-presse/"),
      text: "Espace presse",
    };
    items.push(root);

    const lastBreadCrumbItem: BreadcrumbItem = {
      text: props.pressRelease.title,
    };
    items.push(lastBreadCrumbItem);

    return items;
  });

  const gtmValues = () => {
    const breadcrumbItems = props.pressRelease.breadcrumb.slice(1).toReversed();
    return {
      "all.pageType": "other",
      "all.mainCategory": breadcrumbItems.at(0)?.text ?? "Non défini",
      "all.category": breadcrumbItems.at(1)?.text ?? "Non défini",
      "all.subCategory": breadcrumbItems.at(2)?.text ?? "Non défini",
      "all.subsubCategory": breadcrumbItems.at(3)?.text ?? "Non défini",
      "all.subsubsubCategory": breadcrumbItems.at(4)?.text ?? "Non défini",
      template: "press-release",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />

      <article
        class="common-page-style node-press-release"
        data-ga-zone="in-page"
      >
        <Show
          when={props.pressRelease.field_image?.uri?.url}
          fallback={
            <CommonFirstScreenImage
              image="/images/default-pressrelease-visual.png"
              alt=""
            />
          }
        >
          <CommonFirstScreen fieldImage={props.pressRelease.field_image} />
        </Show>
        <div class="outer-content-area">
          <section class="content-area" data-ga-zone="in-page">
            <div class="inner">
              <CommonIntroduction
                title={props.pressRelease.title}
                catchline={props.pressRelease.field_catchline}
                link={urlRs("press_releases", "/espace-presse/")}
                linkText="Retour"
                publishedDate={formatDateUStoFrenchShortString(
                  props.pressRelease.field_date,
                )}
                shortDate
              />

              <Show when={props.pressRelease.field_sections.length > 0}>
                <div data-test="paragraphs">
                  <For each={props.pressRelease.field_sections}>
                    {(section: SectionParagraph, index) => (
                      <SectionParagraphComponent
                        section={section}
                        index={index() + 1}
                      />
                    )}
                  </For>
                </div>
              </Show>
            </div>
          </section>

          {/* COMMUNIQUE PART */}
          <Show when={props.pressRelease.field_document?.uri?.url}>
            <CommuniquePart
              document={props.pressRelease.field_document!.uri!.url!}
            />
          </Show>

          {/* CONTACT PART */}
          <Show
            when={
              props.pressRelease.field_contacts &&
              props.pressRelease.field_contacts.length > 0
            }
          >
            <ContactPart contacts={props.pressRelease.field_contacts!} />
          </Show>

          {/* RELATED */}
          <Show when={props.pressRelease.field_contents.length > 0}>
            <RelatedContentComponent
              contents={props.pressRelease.field_contents}
            />
          </Show>
        </div>
      </article>

      {/* AUTOPROMOS */}
      <Show when={props.pressRelease.field_ads}>
        <section
          class="autopromos-container"
          data-ga-zone="ads"
          data-test="autopromos"
          classList={{
            single: !hasMultipleShortcodes(props.pressRelease.field_ads!),
            multiple: hasMultipleShortcodes(props.pressRelease.field_ads!),
          }}
        >
          <AutopromosRenderer
            text={props.pressRelease.field_ads!}
            fromFieldName="field_ads"
          />
        </section>
      </Show>

      <Breadcrumb items={breadcrumbItems()} />
    </>
  );
}

function CommuniquePart(props: { document: string }) {
  return (
    <>
      <section class="communique-part" data-test="document">
        <div class="inner">
          <h2>Communiqué de presse</h2>
          <iframe
            src={`/modules/custom/re_utils/pdfjs/web/viewer.html?file=${props.document}`}
          />

          <div class="buttons">
            <a
              href={props.document}
              target="_blank"
              class="btn"
              data-test="btn-pdf"
            >
              Télécharger le document
            </a>
            <a
              href={props.document}
              target="_blank"
              class="btn"
              data-test="btn-full-screen"
            >
              Consulter en plein écran
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
