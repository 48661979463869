import type { Contact } from "~/types/drupal_jsonapi";
import { For } from "solid-js";
import ContactPerson from "~/components/PressReleases/Components/ContactPerson";

import "./ContactPart.css";

export default function ContactPart(props: { contacts: Contact[] }) {
  return (
    <>
      <section class="contact-part" data-test="contacts">
        <div class="inner">
          <h2>Contacts presse</h2>
          <div class="the-contacts">
            <div class="contact-group">
              <div class="contact-list">
                <For each={props.contacts}>
                  {(contact) => {
                    return (
                      <>
                        <ContactPerson contact={contact} />
                      </>
                    );
                  }}
                </For>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
